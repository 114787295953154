/** @jsx jsx */
import { Box, jsx, Link } from "theme-ui";

import LayoutHome from "../layout/LayoutHome";

const TermsOfUseEurope = () => {
  return (
    <LayoutHome
      seoTitle="Terms of use Europe - Donations"
      contentHeaderStyle="contentHeaderText"
      contentHeader={<h3>Terms of use</h3>}
    >
      <Box sx={{ maxWidth: "80%", margin: "20px auto", color: "#333" }}>
        <p>
          <b>1. Introduction</b>
        </p>
        <p>
          These are the terms of use for{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="www.spiritualityfoundation.org"
          >
            www.spiritualityfoundation.org
          </a>{" "}
          (Site). The Site is operated by or on behalf of Sahaj Marg
          Spirituality Foundation, Weinfelden, Switzerland, registered
          CHE-385.034.397 in the Swiss Commercial Register and being a
          tax-exempt non-profit foundation.
        </p>
        <p>
          Your use of the Site will be subject to these terms and by using the
          Site you agree to be bound by them. Use of your personal information
          submitted to or via the Site is governed by our{" "}
          <Link to="/privacy-policy">Privacy and Cookies Policy</Link>.
        </p>
        <p>
          We reserve the right to change these terms from time to time by
          changing them on the Site. These terms is effective from 18th August
          2019.
        </p>
        <p>2. Access to the Site</p>
        <p>
          The Site is intended for use only by persons who are at least 18 years
          of age. By using the Site, you confirm to us that you meet this
          requirement and that, if you are under the age of 18, you have
          received permission from your parent or guardian before using the
          Site.
        </p>
        <p>
          It is your responsibility to ensure your equipment (computer, laptop,
          netbook, tablet or other mobile device) meets all the necessary
          technical specifications to enable you to access and use the Site and
          is compatible with the Site.
        </p>
        <p>
          We may, from time to time, restrict access to certain features, parts
          or content of the Site, or the entire Site, to users who have
          registered with us. You must ensure that any registration details you
          provide are accurate. If you choose, or you are provided with, a
          log-on ID (such as a username and password or other identifier) as
          part of our security procedures, you must treat such information as
          confidential and must not reveal it to anyone else. You are
          responsible for all activities that occur under your log-on ID and
          must notify us immediately of any unauthorised use or other security
          breach of which you become aware. We reserve the right to disable any
          log-on ID, at any time, if in our opinion you have failed to comply
          with any of the provisions of these terms or if any details you
          provide for the purposes of registering as a user prove to be false.
        </p>
        <p>
          We cannot guarantee the continuous, uninterrupted or error-free
          operability of the Site. There may be times when certain features,
          parts or content of the Site, or the entire Site, become unavailable
          (whether on a scheduled or unscheduled basis) or are modified,
          suspended or withdrawn by us, in our sole discretion, without notice
          to you. You agree that we will not be liable to you or to any third
          party for any unavailability, modification, suspension or withdrawal
          of the Site, or any features, parts or content of the Site.
        </p>
        <p>
          <b>3. What you are allowed to do</b>
        </p>
        <p>
          You may only use the Site for non-commercial use and only in
          accordance with these terms. You may retrieve and display content from
          the Site on a computer screen, print and copy individual pages and,
          subject to the next section, store such pages in electronic form.
          Additional terms may also apply to certain features, parts or content
          of the Site and, where they apply, will be displayed on-screen or
          accessible via a link.
        </p>
        <p>
          <b>4. What you are not allowed to do</b>
        </p>
        <p>
          Except to the extent expressly set out in these terms, you are not
          allowed to:
        </p>
        <p>
          You must only use the Site and anything available from the Site for
          lawful purposes (complying with all applicable laws and regulations),
          in a responsible manner, and not in a way that might damage our name
          or reputation or that of any of our affiliates.
        </p>
        <ul
          sx={{
            fontSize: "16px",
            li: { paddingBottom: "10px" },
          }}
        >
          <li>
            ‘scrape’ content or store content of the Site on a server or other
            storage device connected to a network or create an electronic
            database by systematically downloading and storing all of the
            content of the Site;
          </li>
          <li>
            remove or change any content of the Site or attempt to circumvent
            security or interfere with the proper working of the Site or the
            servers on which it is hosted; or
          </li>
          <li>
            create links to the Site from any other website, without our prior
            written consent, although you may link from a website that is
            operated by you provided the link is not misleading or deceptive and
            fairly indicates its destination, you do not imply that we endorse
            you, your website, or any products or services you offer, you link
            to (and do not frame or replicate) the home page of the Site, and
            the linked website does not contain any content that is unlawful,
            threatening, abusive, defamatory, pornographic, obscene, vulgar,
            indecent, offensive or which infringes on the intellectual property
            rights or other rights of any third party.
          </li>
        </ul>
        <p>
          All rights granted to you under these terms will terminate immediately
          in the event that you are in breach of any of them.
        </p>
        <p>
          <b>5. Intellectual property rights</b>
        </p>
        <p>
          All intellectual property rights in any content of the Site (including
          text, graphics, software, photographs and other images, videos, sound,
          trademarks and logos) are owned by us or our licensors. Except as
          expressly set out here, nothing in these terms gives you any rights in
          respect of any intellectual property owned by us or our licensors and
          you acknowledge that you do not acquire any ownership rights by
          downloading content from the Site. In the event you print off, copy or
          store pages from the Site (only as permitted by these terms), you must
          ensure that any copyright, trade mark or other intellectual property
          right notices contained in the original content are reproduced.
        </p>
        <p>
          <b>6. Site features and content</b>
        </p>
        <p>
          We may change the format, features and content of the Site from time
          to time. You agree that your use of the Site is on an ‘as is’ and ‘as
          available’ basis and at your sole risk.
        </p>
        <p>
          Whilst we try to make sure that content on the Site consisting of
          information of which we are the source is correct, you acknowledge
          that the Site may make content available which is derived from a
          number of sources, for which we are not responsible. In all cases,
          information on the Site is not intended to amount to authority or
          advice on which reliance should be placed. You should check with us or
          the relevant information source before acting on any such information.
        </p>
        <p>
          We make or give no representation or warranty as to the accuracy,
          completeness, currency, correctness, reliability, integrity, quality,
          fitness for purpose or originality of any content of the Site and, to
          the fullest extent permitted by law, all implied warranties,
          conditions or other terms of any kind are hereby excluded and we
          accept no liability for any loss or damage of any kind incurred as a
          result of you or anyone else using the Site or relying on any of its
          content.
        </p>
        <p>
          We cannot and do not guarantee that any content of the Site will be
          free from viruses and/or other code that may have contaminating or
          destructive elements. It is your responsibility to implement
          appropriate IT security safeguards (including anti-virus and other
          security checks) to satisfy your particular requirements as to the
          safety and reliability of content.
        </p>
        <p>
          <b>7. User content</b>
        </p>
        <p>
          The Site may, from time to time, allow you to upload user-generated
          content and may also allow you to communicate that content, either to
          selected recipients or in public areas, such as comments pages, chat
          rooms, message boards, and/or news groups (collectively User Content
          Areas). We do not control the material submitted to User Content Areas
          (collectively User Submissions), nor are User Content Areas actively
          moderated. You are solely responsible for the content of your User
          Submissions as submitted by you and acknowledge that all User
          Submissions express the views of their respective authors, and not our
          views.
        </p>
        <p>
          If you participate in any User Content Areas, you must keep all User
          Submissions relevant to the purpose of the User Content Area and the
          nature of any topic.
        </p>
        <p>User Submissions must not:</p>
        <ul
          sx={{
            fontSize: "16px",
            li: { paddingBottom: "10px" },
          }}
        >
          <li>
            contain, transmit, distribute, link to or otherwise make available,
            or advertise or promote any content that infringes any intellectual
            property rights or data protection, privacy or other rights of any
            other person, is defamatory or in breach of any contractual duty or
            any obligation of confidence, is obscene, sexually explicit,
            threatening, abusive, harassing, inciteful of violence or hatred,
            blasphemous, discriminatory (on any ground), liable to cause
            anxiety, alarm or embarrassment, knowingly false or misleading, or
            that does not comply with all applicable laws and regulations or is
            otherwise objectionable (“Prohibited Content”);
          </li>
          <li>
            impersonate any person or entity or otherwise misrepresent your
            relationship with any person or entity;
          </li>
          <li>
            contain, transmit or distribute any unsolicited or unauthorised
            advertising, marketing or promotional material or other form of
            solicitation (spam); or
          </li>
          <li>
            transmit or distribute any virus and/or other code that has
            contaminating or destructive elements.
          </li>
        </ul>
        <p>
          You agree that, by submitting any User Submission, you grant us and
          our affiliates a perpetual, irrevocable, worldwide, non-exclusive,
          royalty-free and fully sub-licensable right and licence to use,
          reproduce, modify, adapt, publish, translate, create derivative works
          from, distribute, perform and display such User Submission (in whole
          or part) and/or to incorporate it in other works in any form, media or
          technology, and you waive any moral rights you may have in, or to be
          identified as the author, of such User Submission.
        </p>
        <p>
          Whilst we do not pre-screen User Submissions, we reserve the right, in
          our sole discretion, to delete, edit or modify any User Submission
          submitted by you and/or to close any topic, at any time without notice
          to you.
        </p>
        <p>
          Complaints about the content of any User Submission must be sent to{" "}
          <a href="mailto:info@sahajmarg.org">info@sahajmarg.org</a> and must
          contain details of the specific User Submission giving rise to the
          complaint.
        </p>
        <p>
          <b>8. External links</b>
        </p>
        <p>
          The Site may, from time to time, include links to external sites,
          which may include links to third party offers and promotions. We
          include these to provide you with access to information, products or
          services that you may find useful or interesting. We are not
          responsible for the content of these sites or for anything provided by
          them and do not guarantee that they will be continuously available.
          The fact that we include links to such external sites does not imply
          any endorsement of or association with their operators or promoters.
        </p>
        <p>
          <b>9. General</b>
        </p>
        <p>
          Nothing in these terms shall limit or exclude our liability to you:
        </p>
        <ul
          sx={{
            fontSize: "16px",
            li: { paddingBottom: "10px" },
          }}
        >
          <li>for death or personal injury caused by our negligence;</li>
          <li>for fraudulent misrepresentation;</li>
          <li>
            for any other liability that, by law, may not be limited or
            excluded.
          </li>
        </ul>
        <p>
          Subject to this, in no event shall we be liable to you for any
          business losses, and if you are a business user, in no event shall we
          be liable to you for any indirect or consequential losses, or for any
          loss of profit, revenue, contracts, data, goodwill or other similar
          losses, and any liability we do have for losses you suffer is strictly
          limited to losses that were reasonably foreseeable.
        </p>
        <p>
          These terms shall be governed by Swiss law, except that (if you are a
          consumer and not a business user).
        </p>
        <p>
          You agree that any dispute between you and us regarding these terms or
          the Site will only be dealt with by the Swiss courts, except that (if
          you are a consumer and not a business user) but if we bring legal
          proceedings, we may only do so in your country.
        </p>
        <p>
          <b>10. Contacting us</b>
        </p>
        <p>
          Please submit any questions you have about these terms or any problems
          concerning the Site by email to{" "}
          <a href="mailto:info@sahajmarg.org">info@sahajmarg.org</a>
          or write to us at:
        </p>
        <a href="mailto:ch.treasurer@sahajmarg.org" sx={{ color: "#333" }}>
          ch.treasurer@sahajmarg.org
        </a>
      </Box>
    </LayoutHome>
  );
};

export default TermsOfUseEurope;
